import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/20210320_155918.jpg'
import Resume1 from '../images/01.jpg'
import Resume2 from '../images/02.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
           <h2 className="icon fa-id-badge major"> About</h2>
          {/* <h2 className="major">About</h2> */}
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Hey there! My name is Rebecca and I'm a manager of Technical Architecture with experience building
            and supporting high trafficked, public-facing web applications and microservices 
            in a "you build it you own it" development environment. My professional experience 
            has allowed for exposure to infrastructure provisioning and maintenance, unit 
            and integration testing, and much more. I pride myself in taking a customer 
            focused approach to software development and technical solutioning through my passion for highly 
            performant and well tested code.
          </p>
          <p>
            Outside of work, I spend my time running, reading anything from Fantasy to Historical Fiction, 
            hiking, and building for fun!
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src={Resume1} alt="" />
            <img src={Resume2} alt="" />
          </span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Portfolio & Contact Details</h2>
          <ul className="icons">
            <li>
              <a
                href="mailto:rschmidt98@gmail.com"
                className="icon fa-envelope-square"
              >
                <span className="label">Email</span>
              </a>
            </li>
            <a
                href="mailto:rschmidt98@gmail.com"
              >Email</a>
            <br/>
            <br/>
            <li>
              <a
                href="https://www.youtube.com/watch?v=xR4C5u1WsLQ&t=2s"
                className="fa fa-youtube-play"
              >
                <span className="label"></span>
              </a>
            </li>
            <a
              href="https://www.youtube.com/watch?v=xR4C5u1WsLQ&t=2s"
            >PrePair Tutoring Application Demo</a>
            <br/>
            <br/>
            <li>
              <a
                Twitter
                href="https://twitter.com/rebeccaschmidtm"
                className="icon fa-twitter"
              >
              <span className="label">Twitter</span>
              </a>
            </li>
            <a
              href="https://twitter.com/rebeccaschmidtm"
            >Twitter</a>
            <br/>
            <br/>

            <li>
              <a
                href="https://medium.com/@rschmidt98"
                className="icon fa-newspaper-o"
              >
                <span className="label">Blog</span>
              </a>
            </li>
            <a
              href="https://medium.com/@rschmidt98"
            >Blog Posts</a>
            <br/>
            <br/>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
